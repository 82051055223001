import React from "react"
import Link from "gatsby-link"
import "../styles/global.css"
import { graphql } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/SEO"

export default ({data}) => (
                    <Layout>
                        <SEO title="JTF starter site page-2" description="A little randomness - powered by Gatsby!" />
                        <div className="content">
                            <h1>Hello world!</h1>
                            <table>
                                <thead>
                                    <tr>
                                    <th>relativepath</th>
                                    <th>extension</th>
                                    <th>prettysize</th>
                                    <th>birthtime</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.allFile.edges.map(({ node }) => 
                                    <tr>
                                        <td>
                                            {node.relativePath}
                                        </td>
                                        <td>
                                            {node.extension}
                                        </td>
                                        <td>
                                            {node.prettySize}
                                        </td>
                                        <td>
                                            {node.birthTime}
                                        </td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                            <Link to="/">Home</Link>
                        </div>
                    </Layout>
)

export const query = graphql `
    query MyFilesQuery {
        allFile {
            edges {
              node {
                extension
                relativePath
                prettySize 
                birthTime(fromNow: true)        
              }
            }
          }
        }
`